/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SVG from 'react-inlinesvg';
import LogoutDialog from "../../../../_helpers/LogoutDialog";
import * as user from '../../../../_mock/user.json'

export function UserProfileDropdown() {
  const [logoutStatus, logout] = useState(false);

  return (
    <>
      {logoutStatus && <LogoutDialog firstName={user?.first_name} closed={logout} />}
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            style={{
              backgroundColor: 'var(--cs-primary-bg)',
              padding: '2px',
            }}
            className={
              'btn btn-icon w-auto d-flex- align-items-center- btn-lg px-1 rounded-pill'
            }
          >
            <div
              className="rounded-circle symbol-light-success text-center symbol-label"
              style={{
                background:
                  user?.pic !== ''
                    ? `url(${user?.pic}) center no-repeat`
                    : `url(${toAbsoluteUrl(
                      '/media/users/blank.png',
                    )}) center no-repeat`,
                backgroundSize: 'cover',
                width: '2.5em',
                height: '2.5em',
              }}
            ></div>
            {/* <span style={{ backgroundColor: 'var(--cs-primary)', color: '#fffff' }} className="rounded-circle symbol-light-success text-center">
        <span style={{ color: '#fff', width: '2.5em', height: '2.5em', padding: '7px', textTransform: 'capitalize' }} className="font-weight-bold d-inline-block text-center" >
          {user?.first_name[0]}
          {user?.last_name[0]}
        </span>
      </span> */}{' '}
            <span className="pl-4  d-flex flex-column justify-content-start align-items-start">
              <span
                style={{ color: 'var(--cs-primary)' }}
                className="font-weight-bold font-size-base d-none d-md-inline mr-3"
              >
                {user.first_name} {user.last_name}
              </span>
              <span
                style={{
                  color: 'var(--cs-primary)',
                  fontSize: '0.6em',
                }}
                className="font-weight-light d-none d-md-inline mr-3"
              >
                {user.phone}
              </span>
            </span>
            <span className="d-flex flex-column align-items-center">
              <KeyboardArrowDownIcon
                style={{
                  color: 'var(--cs-primary)',
                  fontSize: '1.5em',
                }}
              />
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <div className="navi navi-spacer-x-0 pt-5">
            <Link
              to="/profile/personal-info"
              className="navi-item px-8 cursor-pointer"
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/General/User.svg',
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    My Profile
                  </div>
                  <div className="text-muted">
                    Update personal information
                  </div>
                </div>
              </div>
            </Link>

            {/* <Link to="/profile/account-info" className="navi-item px-8 cursor-pointer">
          <div className="navi-link">
            <div className="symbol symbol-40 bg-light mr-3">
              <div className="symbol-label">
                <span className="svg-icon svg-icon-md svg-icon-warning">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}></SVG>
                </span>
              </div>
            </div>
            <div className="navi-text">
              <div className="font-weight-bold">Account Information</div>
              <div className="text-muted">Change account settings</div>
            </div>
          </div>
        </Link> */}

            <Link
              to="/profile/change-password"
              className="navi-item px-8 cursor-pointer"
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/General/Lock.svg',
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    Change Password
                  </div>
                  <div className="text-muted">
                    Change account password
                  </div>
                </div>
              </div>
            </Link>

            {/* <Link to="/profile/alert-settings" className="navi-item px-8 cursor-pointer">
        <div className="navi-link">
          <div className="symbol symbol-40 bg-light mr-3">
            <div className="symbol-label">
              <span className="svg-icon svg-icon-md svg-icon-success">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")}></SVG>
              </span>
            </div>
          </div>
          <div className="navi-text">
            <div className="font-weight-bold">Alert Settings</div>
            <div className="text-muted">
              Manage how you get alerts
              </div>
          </div>
        </div>
      </Link> */}

            <div className="navi-separator mt-3"></div>

            <div className="navi-footer px-8 py-5">
              <button
                onClick={() => { logout(true) }}
                className="btn btn-light-danger font-weight-bold"
              >
                Sign Out
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>);
}
