import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LogoutDialog({ firstName, closed }) {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        closed(false)
    };

    const signOut = () => {
        handleClose();
    };

    return (
        <div>
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>Slide in alert dialog</Button> */}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                disableBackdropClick
                disableEscapeKeyDown
                onClose={handleClose}
                maxWidth="sm"
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className="text-danger"
                >
                    {'Sign out'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Hi {firstName}, you're about to terminate your current session &amp; sign out! As a
                        security precaution, you are required to sign in again to continue.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => console.log('Learn more')}
                        className="btn btn-clean btn-md btn-wide px-5 m-5"
                    >
                        Learn more
                    </Button>
                    <Button
                        onClick={() => signOut()}
                        className="btn btn-danger btn-md btn-wide px-5 m-5"
                    >
                        Sign Out
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
