import React, { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_chamasoft/layout";
import { getStorage, hex2Rgba, rgba2hex } from "../_chamasoft/_helpers";
// import * as theme from "../_chamasoft/_mock/theme.json";
import * as user from "../_chamasoft/_mock/user.json";
import * as settings from "../_chamasoft/_mock/settings.json";

const Dashboard = lazy(() => import('./modules/Dashboard/Dashboard'));
const SetupPage = lazy(() => import('./modules/Setup/Setup'));
const Onboard = lazy(() => import('./modules/Onboard/Onboard'));
const ProfilePage = lazy(() => import('./modules/UserProfile/UserProfilePage'));
const SettingsPage = lazy(() => import('./modules/Settings/SettingsPage'));
// const ReportsPage = lazy(() => import('./modules/Reports/Reports'));
const ECommercePage = lazy(() => import("./modules/ECommerce/pages/eCommercePage"));
const MaterialComponentsPage = lazy(() => import("./modules/MaterialComponents/MaterialComponentsPage"));


export default function BasePage() {

  const [fetchingData, setFetchingData] = useState(true);
  const [fetchingSettings, setFetchingSettings] = useState(true);
  const [theme, setTheme] = useState(user.profiles.active?.theme);

  useEffect(() => {
    if (getStorage('active_profile')) {
      const activeProfile = JSON.parse(getStorage('active_profile')) || { theme: {} };
      setTheme(activeProfile?.theme);
    } else {
      setTheme(user.profiles.active.theme);
    }

    // Mock delay
    setTimeout(() => {
      setFetchingData(false);
      setFetchingSettings(false);
    }, 1000);

    if (
      theme?.pantone?.color1 &&
      theme?.pantone?.color1 !== '' &&
      theme?.pantone?.color1 !== '#undefined' &&
      !fetchingSettings &&
      settings?.disableCustomBranding !== 'true'
    ) {
      document.documentElement.style.setProperty(
        '--cs-primary',
        hex2Rgba(theme?.pantone?.color1, 1),
      );
      document.documentElement.style.setProperty(
        '--cs-primary-bg',
        hex2Rgba(theme?.pantone?.color1, 0.08),
      );
      document.documentElement.style.setProperty(
        '--cs-menu-bg',
        hex2Rgba(theme?.pantone?.color1, 0.18),
      );
      document.documentElement.style.setProperty(
        '--cs-submenu-bg',
        hex2Rgba(theme?.pantone?.color1, 0.08),
      );
      document.documentElement.style.setProperty(
        '--cs-submenu-bg-hover',
        hex2Rgba(theme?.pantone?.color1, 0.12),
      );
    } else {
      document.documentElement.style.setProperty(
        '--cs-primary',
        hex2Rgba('#3699ff', 1),
      );
      document.documentElement.style.setProperty(
        '--cs-primary-bg',
        hex2Rgba('#eaf2f5', 0.08),
      );
      document.documentElement.style.setProperty(
        '--cs-menu-bg',
        hex2Rgba('#eaeef3', 0.18),
      );
      document.documentElement.style.setProperty(
        '--cs-submenu-bg',
        hex2Rgba('#eaeef3', 0.08),
      );
      document.documentElement.style.setProperty(
        '--cs-submenu-bg-hover',
        hex2Rgba('#f3f6f9', 0.12),
      );
    }

    if (
      theme?.pantone?.color2 &&
      theme?.pantone?.color2 !== '' &&
      theme?.pantone?.color2 !== '#undefined' &&
      !fetchingSettings &&
      settings?.disableCustomBranding !== 'true'
    ) {
      document.documentElement.style.setProperty(
        '--cs-secondary',
        hex2Rgba(theme?.pantone?.color2, 1),
      );
      document.documentElement.style.setProperty(
        '--cs-secondary-lighter',
        rgba2hex(hex2Rgba(theme?.pantone?.color2, 0.6)),
      );
    } else {
      document.documentElement.style.setProperty(
        '--cs-secondary',
        'rgba(45, 87, 106, 1)',
      );
      document.documentElement.style.setProperty(
        '--cs-secondary-lighter',
        rgba2hex('rgba(76, 152, 187, 1)'),
      );
    }
  }, [fetchingSettings, theme.pantone.color1, theme.pantone.color2]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {fetchingData && <LayoutSplashScreen />}
      {!fetchingData && (
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }

          <ContentRoute path="/setup" component={SetupPage} />
          <ContentRoute path="/dashboard" component={Dashboard} />
          <ContentRoute path="/onboard" component={Onboard} />
          <ContentRoute path="/profile" component={ProfilePage} />
          {/* <ContentRoute path="/reports" component={ReportsPage} /> */}
          <ContentRoute path="/settings" component={SettingsPage} />
          <ContentRoute path="/components" component={MaterialComponentsPage} />
          <ContentRoute path="/tables" component={ECommercePage} />
          {/* <Redirect to="error/error-v1" /> */}
          <Redirect to="/dashboard" />

        </Switch>
      )}
    </Suspense>
  );
}
