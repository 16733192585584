/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react';
import { Tab, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SVG from 'react-inlinesvg';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../../_core/ChamasoftLayout';
import { getStorage, setStorage, toAbsoluteUrl } from '../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import { CircularProgress } from '@material-ui/core';
import * as user from '../../../../_mock/user.json'

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};

export function SwitcherDropdown() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas:
                objectPath.get(
                    uiService.config,
                    'extras.notifications.layout',
                ) === 'offcanvas',
        };
    }, [uiService]);

    const fetchingData = false;
    const profiles = user.profiles.profiles;
    const fetchingSettings = false;
    const settings = {};
    const [activeProfile, setActiveProfile] = useState({});
    const [selected, setSelected] = useState(null);

    const switchOption = (profile, i) => {
        if (
            profiles[i].is_validated === 0 ||
            profiles[i]._id === activeProfile?._id
        )
            return;
        setSelected(i);
        setTimeout(() => {
            setActiveProfile(profile);
            setStorage('active_profile', JSON.stringify(profile));
            setSelected(null);
            window.location.reload();
        }, 1000);
        // console.log(profile);
    };

    useEffect(() => {
        if (getStorage('active_profile')) {
            setActiveProfile(JSON.parse(getStorage('active_profile')));
        } else {
            setActiveProfile(user.profiles.active);
        }
    }, [])

    return (
        <>
            {layoutProps.offcanvas &&
                !fetchingSettings &&
                settings?.hideAppSwitcher !== 'true' && (
                    <div className="topbar-item">
                        <div
                            className="btn btn-icon btn-clean btn-lg mr-1"
                            id="kt_quick_switcher_toggle"
                        >
                            <span className="svg-icon svg-icon-xl svg-icon-primary">
                                <SVG
                                    src={toAbsoluteUrl(
                                        '/media/svg/icons/Layout/Layout-4-blocks.svg',
                                    )}
                                />
                            </span>
                        </div>
                    </div>
                )}
            {!layoutProps.offcanvas &&
                !fetchingSettings &&
                settings?.hideAppSwitcher !== 'true' && (
                    <Dropdown drop="down" alignRight>
                        <Dropdown.Toggle
                            as={DropdownTopbarItemToggler}
                            id="kt_quick_switcher_toggle"
                        >
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="user-notification-tooltip">
                                        Options
                                    </Tooltip>
                                }
                            >
                                <div
                                    className="btn btn-icon btn-clean btn-lg mr-5 btn-pill"
                                    id="kt_quick_switcher_toggle"
                                >
                                    <span className="svg-icon svg-icon-xl svg-icon-primary">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                '/media/svg/icons/Layout/Layout-4-blocks.svg',
                                            )}
                                        />
                                    </span>
                                </div>
                            </OverlayTrigger>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                            <form>
                                {/** Head */}
                                <div
                                    className="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top"
                                    style={{ background: `var(--cs-primary)` }}
                                >
                                    <Tab.Container defaultActiveKey={'Options'}>
                                        <Tab.Content className="tab-content">
                                            <Tab.Pane
                                                eventKey="Options"
                                                id="topbar_switcher_events"
                                            >
                                                <PerfectScrollbar
                                                    options={
                                                        perfectScrollbarOptions
                                                    }
                                                    className="navi navi-hover scroll"
                                                    style={{
                                                        maxHeight: '380px',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <span className="navi-item cursor-pointer">
                                                        <div className="navi-link">
                                                            <div className="navi-icon mr-4">
                                                                <span className="svg-icon svg-icon-xl svg-icon-">
                                                                    <SVG
                                                                        src={toAbsoluteUrl(
                                                                            '/media/svg/icons/Navigation/Plus.svg',
                                                                        )}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div className="navi-text">
                                                                <div className="font-weight-bolder">
                                                                    Add New
                                                                </div>
                                                                <div className="opacity-70">
                                                                    <small>
                                                                        Register Bank, SACCO, Chama, Property &amp; More
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    {!fetchingData &&
                                                        profiles?.map(
                                                            (profile, i) => {
                                                                return (
                                                                    <span
                                                                        onClick={() =>
                                                                            switchOption(
                                                                                profile,
                                                                                i,
                                                                            )
                                                                        }
                                                                        className={
                                                                            'navi-item ' +
                                                                            (profile.is_validated ===
                                                                                0 ? 'opacity-60 ' :
                                                                                profile._id ===
                                                                                    activeProfile?._id
                                                                                    ? 'cursor-default'
                                                                                    : 'cursor-pointer')
                                                                        }
                                                                        key={
                                                                            'profile-' +
                                                                            i
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                'navi-link ' +
                                                                                (profile._id ===
                                                                                    activeProfile?._id
                                                                                    ? 'active'
                                                                                    : '')
                                                                            }
                                                                        >
                                                                            <div className="navi-icon mr-4">
                                                                                <span className="svg-icon svg-icon-xl svg-icon-dark-50">
                                                                                    <SVG
                                                                                        src={toAbsoluteUrl(
                                                                                            '/media/svg/icons/Communication/Group.svg',
                                                                                        )}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="navi-text">
                                                                                <div className="font-weight-bolder">
                                                                                    {
                                                                                        profile.name
                                                                                    }
                                                                                </div>
                                                                                <div className="opacity-70">
                                                                                    <small>
                                                                                        <strong>
                                                                                            {profile.profile_type}
                                                                                        </strong>

                                                                                        ,{' '}
                                                                                        {profile.role_name || 'Administrator'}
                                                                                    </small>
                                                                                    {profile.is_validated ===
                                                                                        0 && (
                                                                                            <small className="d-block text-danger">
                                                                                                Pending verification
                                                                                            </small>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                            {selected ===
                                                                                i && (
                                                                                    <div className="ml-4">
                                                                                        <CircularProgress
                                                                                            variant="indeterminate"
                                                                                            disableShrink
                                                                                            size={
                                                                                                16
                                                                                            }
                                                                                            thickness={
                                                                                                4
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            {profile._id ===
                                                                                activeProfile?._id && (
                                                                                    <div className="navi-icon ml-4">
                                                                                        <span className="svg-icon svg-icon-xl svg-icon-primary">
                                                                                            <SVG
                                                                                                src={toAbsoluteUrl(
                                                                                                    '/media/svg/icons/Navigation/Check.svg',
                                                                                                )}
                                                                                            />
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </span>
                                                                );
                                                            },
                                                        )}
                                                </PerfectScrollbar>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </form>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
        </>
    );
}
