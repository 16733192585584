import React, { useEffect, useMemo, useState } from 'react';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/ChamasoftLayout';
import { Topbar } from './Topbar';
import { HeaderMenuWrapper } from './header-menu/HeaderMenuWrapper';
import { AnimateLoading } from '../../../_partials/controls';
import ContentLoader from 'react-content-loader'; // Create skeleton here: https://skeletonreact.com/
// import * as actions from "../../../../app/modules/Auth/_redux/authRedux";
import { Badge } from 'react-bootstrap';
import * as user from '../../../_mock/user.json'
import { getStorage } from '../../../_helpers';

export function Header() {
  const uiService = useHtmlClassService();
  const [activeProfile, setActiveProfile] = useState({});

  const [fetchingDataStatus, setFetchingDataStatus] = useState(true);

  useEffect(() => {
    if (getStorage('active_profile')) {
      setActiveProfile(JSON.parse(getStorage('active_profile')));
    } else {
      setActiveProfile(user?.profiles?.active);
    }
    setTimeout(() => setFetchingDataStatus(false), 3000);
  }, []);

  const renderFetchData = () => {
    return fetchingDataStatus ? (
      <div className="d-block" style={{ overflow: 'hidden' }}>
        <ContentLoader
          speed={2}
          width={180}
          height={37}
          viewBox="0 0 180 37"
          backgroundColor="#ededed"
          foregroundColor="#dbdbdb"
        >
          {/* <rect x="0" y="0" rx="3" ry="3" width="30" height="30" />
          <rect x="40" y="0" rx="3" ry="3" width="140" height="16" />
          <rect x="40" y="21" rx="2" ry="2" width="90" height="9" /> */}
          <rect x="0" y="0" rx="3" ry="3" width="140" height="16" />
          <rect x="0" y="21" rx="2" ry="2" width="90" height="9" />
        </ContentLoader>
      </div>
    ) : (
      <></>
    );
  };

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses(
        'header_container',
        true,
      ),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        'header.menu.self.display',
      ),
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {!layoutProps.menuHeaderDisplay && <div />}
          {/*end::Header Menu Wrapper*/}

          <div
            className="header-menu-wrapper header-menu-wrapper-left d-none d-lg-block"
            style={{ minWidth: '320px' }}
          >
            <div className="py-4">
              {renderFetchData()}
              <div
                className={
                  fetchingDataStatus
                    ? 'd-none'
                    : 'd-inline-block'
                }
              >
                <div
                  className="font-weight-bolder font-size-h6 text-dark-50- mb-0 float-left"
                  style={{
                    color: 'var(--cs-secondary)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                    width: 'auto',
                  }}
                >
                  {activeProfile?.name}
                </div>
                <div className="d-inline-block">
                  &nbsp;&nbsp;
                  <Badge variant="primary">{(activeProfile?.is_owner === 1 ? 'Admin' : 'User')}</Badge>
                </div>
                <small
                  className="d-block text-dark-50- opacity-70"
                  style={{ color: 'var(--cs-secondary)' }}
                >
                  {activeProfile?.website}
                </small>
              </div>
            </div>
          </div>

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
